import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import UserLogin from '@aurora/shared-client/components/users/UserLogin/UserLogin';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import type { MessageDraftHistoryEdge } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext, useId, useState } from 'react';
import { Dropdown, useClassNameMapper, Modal } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import type {
  MessageRevisionDiffQuery,
  MessageRevisionDiffQueryVariables
} from '../../../types/graphql-types';
import ContentComparison from '../../contentComparison/ContentComparison';
import UserLink from '../../users/UserLink/UserLink';
import useTranslation from '../../useTranslation';
import localStyles from './MessageCompareRevisions.module.pcss';
import messageRevisionDiffQuery from './MessageRevisionsDiff.query.graphql';
import { useUIDSeed } from 'react-uid';

interface Props {
  /**
   * Whether the modal is currently being displayed.
   */
  show: boolean;
  /**
   * Callback function when the modal is hidden
   *
   * @callback
   * @param boolean - The state of the modal.
   */
  onHide: () => void;
  /**
   * The version selected on the history modal for comparison.
   */
  selectedVersion: MessageDraftHistoryEdge;
  /**
   * The list of all the versions of a message.
   */
  versionList: Array<MessageDraftHistoryEdge>;
}

/**
 *  Modal for comparing two versions of a message.
 *
 * @constructor
 *
 * @author Aditi Agarwal
 */
const MessageCompareRevisionsModal: React.FC<React.PropsWithChildren<Props>> = ({
  show,
  onHide,
  versionList,
  selectedVersion
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_COMPARE_REVISIONS
  );
  const uid = useId();
  const uidSeed = useUIDSeed();
  const { contextMessage } = useContext<AppContextInterface>(AppContext);
  const { formatDate } = useDateTime();
  const [selectedSourceVersion, setSelectedSourceVersion] =
    useState<MessageDraftHistoryEdge>(selectedVersion);

  const [selectedTargetVersion, setSelectedTargetVersion] = useState<MessageDraftHistoryEdge>(
    versionList[0]
  );

  const log = getLog(module);

  const validVersions = versionList.filter(item => {
    return !item.node.deleted;
  });
  const uniqueVersionList = [
    ...new Map(validVersions.map(item => [JSON.stringify(item?.node?.version), item])).values()
  ];

  const sourceRevision = selectedSourceVersion?.node?.revision;
  const targetRevision = selectedTargetVersion?.node?.revision;

  const { data: queryData, error: queryError } = useQueryWithTracing<
    MessageRevisionDiffQuery,
    MessageRevisionDiffQueryVariables
  >(module, messageRevisionDiffQuery, {
    variables: {
      id: contextMessage?.id,
      constraints: {
        sourceRevisionRef: {
          eq: `revision:${sourceRevision}`
        },
        targetRevisionRef: { eq: `revision:${targetRevision}` }
      }
    },
    skip: !selectedSourceVersion
  });

  if (queryError) {
    log.error(queryError, 'Error getting revisions', queryError?.message);
  }

  if (textLoading) {
    return null;
  }

  const { result } = queryData?.message.revisionsDiff || {};

  /**
   * Returns if the selected version is the latest version of a message.
   *
   * @param version The version selected.
   */
  function isLatestVersion(version): boolean {
    return isEqual(version, uniqueVersionList[0]);
  }

  /**
   * Function to set the target version when version is selected.
   *
   * @param item The version selected.
   */
  function handleTargetVersion(item) {
    setSelectedTargetVersion({ ...selectedTargetVersion, node: item?.node });
  }

  /**
   * Function to set the source version when version is selected.
   *
   * @param item The version selected.
   */
  function handleSourceVersion(item) {
    setSelectedSourceVersion({ ...selectedSourceVersion, node: item?.node });
  }

  /**
   * Renders the list of versions for a message.
   *
   * @param sourceVersion The source version selected.
   * @param targetVersion The target version selected.
   * @param isSourceVersion If it is a source version.
   */
  function renderListOfVersions(
    sourceVersion: MessageDraftHistoryEdge,
    targetVersion: MessageDraftHistoryEdge,
    isSourceVersion: boolean
  ): JSX.Element[] {
    return uniqueVersionList.map((item, index) => (
      <Dropdown.Item
        className={cx({ 'd-none': isEqual(item, sourceVersion) || isEqual(item, targetVersion) })}
        key={uidSeed(`${index}-${item?.node.revision}`)}
        onSelect={(): void =>
          isSourceVersion ? handleSourceVersion(item) : handleTargetVersion(item)
        }
      >
        {formatMessage('version', {
          major: item?.node.version.major,
          minor: item?.node.version.minor
        })}
      </Dropdown.Item>
    ));
  }

  /**
   * Renders the header to select the versions for comparing.
   */
  function renderCompareHeader(): React.ReactElement {
    return (
      <div className={cx('lia-compare-container-header')}>
        <div className={cx('lia-compare-header-left')}>
          <span>{formatMessage('comparing')}</span>
          <Dropdown drop="down">
            <Dropdown.Toggle
              as={Button}
              aria-label={formatMessage('toggleButtonSourceLabel')}
              variant={ButtonVariant.NO_VARIANT}
              size="sm"
              className={cx('lia-g-dropdown-toggle lia-dropdown-toggle')}
            >
              {selectedTargetVersion && (
                <span className={cx('lia-g-divider')}>
                  {formatMessage('version', {
                    major: selectedSourceVersion?.node.version.major,
                    minor: selectedSourceVersion?.node.version.minor
                  })}
                </span>
              )}
              {isLatestVersion(selectedSourceVersion) && (
                <span className={cx('lia-g-divider')}>{formatMessage('current')}</span>
              )}
            </Dropdown.Toggle>
            {uniqueVersionList.length > 2 && (
              <Dropdown.Menu
                renderOnMount
                className={cx('lia-dropdown-menu')}
                popperConfig={dropdownPopperConfig}
              >
                {renderListOfVersions(selectedSourceVersion, selectedTargetVersion, true)}
              </Dropdown.Menu>
            )}
          </Dropdown>
          <span>{formatMessage('with')}</span>
          <Dropdown drop="down">
            <Dropdown.Toggle
              as={Button}
              size="sm"
              aria-label={formatMessage('toggleButtonTargetLabel')}
              className={cx('lia-g-dropdown-toggle lia-dropdown-toggle')}
              variant={ButtonVariant.NO_VARIANT}
            >
              {selectedTargetVersion && (
                <span className={cx('lia-g-divider')}>
                  {formatMessage('version', {
                    major: selectedTargetVersion?.node.version.major,
                    minor: selectedTargetVersion?.node.version.minor
                  })}
                </span>
              )}
              {isLatestVersion(selectedTargetVersion) && (
                <span className={cx('lia-g-divider')}>{formatMessage('current')}</span>
              )}
            </Dropdown.Toggle>
            {uniqueVersionList.length > 2 && (
              <Dropdown.Menu
                renderOnMount
                popperConfig={dropdownPopperConfig}
                className={cx('lia-dropdown-menu')}
              >
                {renderListOfVersions(selectedTargetVersion, selectedSourceVersion, false)}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        <div className={cx('lia-compare-tags-wrapper')}>
          <span className={cx('lia-compare-tags lia-compare-tags-added')}>
            {formatMessage('added')}
          </span>
          <span className={cx('lia-compare-tags lia-compare-tags-removed')}>
            {formatMessage('removed')}
          </span>
          <span className={cx('lia-compare-tags lia-compare-tags-changed')}>
            {formatMessage('changed')}
          </span>
        </div>
      </div>
    );
  }

  /**
   * Renders the version details.
   *
   * @param version The version selected.
   */
  function renderVersionDetails(version: MessageDraftHistoryEdge): React.ReactElement {
    return (
      <>
        <span className={cx('lia-compare-version-details-content lia-g-divider')}>
          {formatMessage('version', {
            major: version?.node.version.major,
            minor: version?.node.version.minor
          })}
        </span>
        {isLatestVersion(version) && (
          <span className={cx('lia-compare-version-details-content lia-g-divider')}>
            {formatMessage('current')}
          </span>
        )}
        <span> {formatMessage('by')} </span>
        <UserLink
          user={version.node.changedBy}
          className={cx('lia-compare-version-details-content')}
          useHoverCard={false}
        >
          <UserLogin user={version.node.changedBy} />
        </UserLink>
        <span> {formatMessage('on')} </span>
        <span>
          <span className={cx('lia-compare-time-text')}>
            {version?.node?.changedOn &&
              formatDate(version?.node?.changedOn, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric'
              })}
          </span>
          <span>
            {version?.node?.changedOn &&
              formatDate(version?.node?.changedOn, {
                hour: 'numeric',
                minute: 'numeric'
              })}
          </span>
        </span>
      </>
    );
  }

  /**
   * Renders the details of source and target revisions to compare.
   */
  function renderCompareDetails(): React.ReactElement {
    return (
      <div className={cx('lia-compare-version-details')}>
        <span className={cx('lia-compare-version-details-text')}>
          {renderVersionDetails(selectedSourceVersion)}
        </span>
        <span>{renderVersionDetails(selectedTargetVersion)}</span>
      </div>
    );
  }

  return (
    <Modal
      size="lg"
      className={cx('lia-modal')}
      scrollable
      centered
      aria-labelledby={uid}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={cx('lia-header')} closeButton>
        <Modal.Title as="h4" id={uid} data-testid="CompareVersionsModal.Title">
          {formatMessage('compareVersions')}
        </Modal.Title>
      </Modal.Header>
      {renderCompareHeader()}
      <Modal.Body>
        <div className={cx('lia-container')}>
          {renderCompareDetails()}
          <ContentComparison diffResult={result} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MessageCompareRevisionsModal;
