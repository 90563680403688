import { makeVar } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { ToggleableState } from '@aurora/shared-client/components/form/enums';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import UserLogin from '@aurora/shared-client/components/users/UserLogin/UserLogin';
import { convertTimezone } from '@aurora/shared-client/helpers/forms/DateHelper/DateHelper';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import Icons from '@aurora/shared-client/icons';
import type { MessageEditPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Board,
  FriendlyVersion,
  MessageDraftHistory,
  MessageDraftHistoryEdge
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  PostMessageType,
  WorkflowAction
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { ContextMessageFragment } from '@aurora/shared-generated/types/graphql-types';
import { KeyBoardEvents } from '@aurora/shared-types/community/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useRef, useState } from 'react';
import { Accordion, Dropdown, useClassNameMapper } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { MessageEditingState } from '@aurora/shared-client/helpers/ui/GlobalState';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import { CoAuthorsAndContributorsWorkflow, PublishedState } from '../../../types/enums';
import useTranslation from '../../useTranslation';
import localStyles from './MessageVersionDrawer.module.pcss';
import { useUIDSeed } from 'react-uid';

export const versionInfo = makeVar<FriendlyVersion>(null);

interface Props {
  /**
   * The version of the message to be displayed as a drawer.
   */
  version: MessageDraftHistoryEdge;
  /**
   * The list of versions of the message.
   */
  versionList: Array<MessageDraftHistoryEdge>;
  /**
   * The state of the version.
   */
  publishState: PublishedState;
  /**
   * Callback for version comparison.
   */
  onVersionCompare: (highlightedVersion: MessageDraftHistoryEdge) => void;
  /**
   * The message of which the version history is to be shown.
   */
  message: Omit<
    ContextMessageFragment,
    | 'postTime'
    | 'subscribed'
    | 'solution'
    | 'metrics'
    | 'contributors'
    | 'nonCoAuthorContributors'
    | 'coAuthors'
    | 'author'
    | 'attachments'
    | 'currentRevision'
    | 'conversation'
    | 'parent'
    | 'hasGivenKudo'
    | 'kudosSumWeight'
    | 'visibilityScope'
    | 'messagePolicies'
    | 'customFields'
  >;
}

const MessageVersionDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  version,
  message,
  versionList,
  publishState,
  onVersionCompare
}) => {
  const uidSeed = useUIDSeed();
  const { Link } = useEndUserRoutes();
  const cx = useClassNameMapper(localStyles);
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_VERSION_DRAWER);
  const { formatRelativeTime, formatDate } = useDateTime();
  const [isActive, setIsActive] = useState(false);
  const accordionReference = useRef(null);
  const currentVersion = versionList && versionList[0]?.node.version;
  const currentRevision = versionList[0]?.node.revision;
  const [accordionState, setAccordionState] = useState(
    version.node.revision === currentRevision ? ToggleableState.OPEN : ToggleableState.CLOSED
  );
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);

  /**
   * Whether to render action menu for history items, excludes current version and invalid state changes.
   */
  function showActionMenu(edge: MessageDraftHistoryEdge): boolean {
    return !(
      isEqual(edge?.node?.version, currentVersion) ||
      (edge?.node?.workflowAction !== WorkflowAction.SaveDraft.toLowerCase() &&
        edge?.node?.workflowAction !== WorkflowAction.Publish.toLowerCase())
    );
  }

  /**
   * Renders action menu for the history
   *
   * @param highlightedVersion MessageDraftHistoryEdge
   */
  function renderActionMenu(highlightedVersion: MessageDraftHistoryEdge): React.ReactElement {
    const { messageEditPage } = ConversationStyleBehaviorHelper.getInstance(message.board as Board);
    const messageEditParams = {
      boardId: message.board.displayId,
      messageSubject: String(UrlHelper.determineSlugForMessagePath(message)),
      messageId: String(message?.uid)
    };
    const localEditState: MessageEditingState = {
      messageId: String(message?.uid),
      postMessageType: PostMessageType.Edit,
      editorLocation: EditorLocation.INLINE
    };
    const revisionId = `${highlightedVersion?.node?.revision}`;
    return (
      <Dropdown
        drop="down"
        data-testid="VersionHistoryActionMenu"
        onToggle={isOpen => setIsActive(isOpen)}
      >
        <Dropdown.Toggle
          variant={ButtonVariant.NO_VARIANT}
          as={Button}
          aria-label={
            publishState === PublishedState.PUBLISH
              ? formatMessage('publish_button')
              : formatMessage('draft_label', {
                  version_number: formatMessage('version', {
                    major: version.node.version.major,
                    minor: version.node.version.minor
                  })
                })
          }
          className={cx('lia-g-icon-btn lia-state-toggle', { 'lia-menu-open': isActive })}
        >
          <Icon icon={Icons.EllipsisIcon} size={IconSize.PX_16} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight
          renderOnMount
          popperConfig={{ ...dropdownPopperConfig, strategy: 'fixed' }}
        >
          <Dropdown.Item onClick={() => onVersionCompare(highlightedVersion)}>
            {formatMessage('compare')}
          </Dropdown.Item>
          <Dropdown.Item>
            <Link<MessageEditPagesAndParams>
              passHref
              route={messageEditPage}
              params={messageEditParams}
              query={{ revisionId: revisionId }}
              legacyBehavior={true}
            >
              <span
                role="button"
                tabIndex={0}
                onKeyPress={(): void => setMessageEditingState(localEditState)}
                onClick={(): void => {
                  setMessageEditingState(localEditState);
                  versionInfo(highlightedVersion.node.version);
                }}
              >
                {formatMessage('editAndRestore')}
              </span>
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  /**
   * Whether the messageVersion workflow action is co-author or contributors workflow action
   * @param messageVersion Message version
   * @return Whether the messageVersion workflow action is co-author or contributors workflow action
   */
  function isCoAuthorsAndContributorsWorkflow(messageVersion: MessageDraftHistoryEdge): boolean {
    return (
      messageVersion.node.workflowAction === CoAuthorsAndContributorsWorkflow.ADD_CONTRIBUTOR ||
      messageVersion.node.workflowAction === CoAuthorsAndContributorsWorkflow.REMOVE_CONTRIBUTOR ||
      messageVersion.node.workflowAction === CoAuthorsAndContributorsWorkflow.ADD_CO_AUTHOR ||
      messageVersion.node.workflowAction === CoAuthorsAndContributorsWorkflow.REMOVE_CO_AUTHOR
    );
  }

  /**
   * Renders the details of each version.
   *
   * @param selectedVersion The version selected.
   * @param publishedState The state of publish (pre or post).
   */
  function fetchVersionDetails(
    selectedVersion: FriendlyVersion,
    publishedState: PublishedState
  ): Array<React.ReactElement> {
    let versionDetails = [];
    if (publishedState === PublishedState.POST_PUBLISH) {
      versionDetails = versionList.filter(messageVersion => {
        return (
          messageVersion.node.workflowAction !== WorkflowAction.Publish.toLowerCase() &&
          !messageVersion.node.deleted &&
          isEqual(messageVersion.node.version, selectedVersion)
        );
      });
    } else {
      versionDetails = versionList.filter(messageVersion => {
        return (
          (Number.parseInt(messageVersion.node.version.major) ===
            Number.parseInt(selectedVersion.major) - 1 &&
            Number.parseInt(messageVersion.node.version.minor) &&
            !messageVersion.node.deleted &&
            (messageVersion.node.workflowAction === WorkflowAction.SaveDraft.toLowerCase() ||
              isCoAuthorsAndContributorsWorkflow(messageVersion))) ||
          (messageVersion.node.version.major === selectedVersion.major &&
            messageVersion.node.workflowAction === WorkflowAction.Publish.toLowerCase()) ||
          (isEqual(messageVersion.node.version, selectedVersion) &&
            isCoAuthorsAndContributorsWorkflow(messageVersion)) ||
          (messageVersion.node.workflowAction === 'delete_draft' &&
            Number.parseInt(messageVersion.node.version.major) + 1 ===
              Number.parseInt(selectedVersion.major))
        );
      });
    }

    /**
     * Renders the version title based on workflow action.
     *
     * @param messageVersionNode Message version node.
     * @return the version title
     */
    function renderVersionTitle(messageVersionNode: MessageDraftHistory): string {
      if (
        messageVersionNode.workflowAction === CoAuthorsAndContributorsWorkflow.ADD_CONTRIBUTOR ||
        messageVersionNode.workflowAction === CoAuthorsAndContributorsWorkflow.REMOVE_CONTRIBUTOR
      ) {
        return formatMessage(messageVersionNode.workflowAction, {
          contributor: messageVersionNode.contributor.login
        });
      }
      if (
        messageVersionNode.workflowAction === CoAuthorsAndContributorsWorkflow.ADD_CO_AUTHOR ||
        messageVersionNode.workflowAction === CoAuthorsAndContributorsWorkflow.REMOVE_CO_AUTHOR
      ) {
        return formatMessage(messageVersionNode.workflowAction, {
          coAuthor: messageVersionNode.coAuthor.login
        });
      }
      return formatMessage(messageVersionNode.workflowAction);
    }

    return versionDetails.map((messageVersion, index, messageVersions) => (
      <span
        className={cx('lia-accordion-content')}
        key={uidSeed(`${index}-${messageVersion.node.revision}`)}
      >
        <span className={cx('d-flex justify-content-between')}>
          <span className={cx('d-flex')}>
            <UserAvatar user={messageVersion.node.changedBy} size={IconSize.PX_40} />
            <span className={cx('d-flex flex-column lia-g-ml-10')}>
              <span className={cx('lia-version-title')}>
                {renderVersionTitle(messageVersion.node)}
              </span>
              <small>
                <UserLogin className={cx('lia-g-divider')} user={messageVersion.node.changedBy} />
                <span className={cx('lia-version-metadata lia-g-divider')}>
                  <span className={cx('lia-compare-time-text')}>
                    {formatDate(messageVersion.node.changedOn, {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric'
                    })}
                  </span>
                  {formatDate(messageVersion.node.changedOn, {
                    hour: 'numeric',
                    minute: 'numeric'
                  })}
                </span>
                {messageVersion.node.scheduledPublishTime && (
                  <div className={cx('lia-version-metadata font-weight-bold')}>
                    <span className={cx('lia-compare-time-text')}>
                      {messageVersion.node.scheduledTimezone &&
                        `${convertTimezone(
                          new Date(messageVersion.node.scheduledPublishTime),
                          messageVersion.node.scheduledTimezone
                        )}, ${messageVersion.node.shortScheduledTimezone}`}
                    </span>
                  </div>
                )}
                {messageVersion.node.workflowAction ===
                  WorkflowAction.CancelSchedulePublication.toLowerCase() && (
                  <span
                    className={cx(
                      'lia-version-metadata font-weight-bold lia-cancel-schedule d-block'
                    )}
                  >
                    <span className={cx('lia-compare-time-text')}>
                      {index + 1 < messageVersions.length &&
                        messageVersions[index + 1].node.scheduledTimezone &&
                        `${convertTimezone(
                          new Date(messageVersions[index + 1].node.scheduledPublishTime),
                          messageVersions[index + 1].node.scheduledTimezone
                        )}, ${messageVersions[index + 1].node.shortScheduledTimezone}`}
                    </span>
                  </span>
                )}
              </small>
            </span>
          </span>
          <span className={cx('d-flex flex-column lia-g-ml-10')}>
            {showActionMenu(messageVersion) && renderActionMenu(messageVersion)}
          </span>
        </span>
        {messageVersion.node.reviewComment && (
          <span className={cx('small lia-revision-note d-block')}>
            {`"${messageVersion.node.reviewComment}"`}
          </span>
        )}
      </span>
    ));
  }

  return (
    <Accordion
      role="group"
      as="span"
      className={cx('lia-accordion d-block')}
      defaultActiveKey={currentRevision}
      tabIndex={0}
      onKeyPress={(event): void =>
        event.key === KeyBoardEvents.ENTER &&
        (event.target as HTMLElement).contains(accordionReference.current) &&
        accordionReference.current.click()
      }
    >
      <Accordion.Toggle
        as="button"
        className={cx('lia-version-accordion-toggle border-top shadow-sm', {
          'lia-accordion-is-open': accordionState === ToggleableState.OPEN
        })}
        ref={accordionReference}
        eventKey={version.node.revision}
        onClick={(): void => {
          setAccordionState(
            accordionState === ToggleableState.OPEN ? ToggleableState.CLOSED : ToggleableState.OPEN
          );
        }}
        aria-expanded={accordionState === ToggleableState.OPEN}
        aria-label={formatMessage(
          version?.node?.workflowAction === 'delete_draft'
            ? formatMessage('draft_deleted', {
                version_number: formatMessage('version', {
                  major: version.node.version.major,
                  minor: formatMessage('version_unknown')
                })
              })
            : isEqual(currentVersion, version.node.version)
            ? 'current_version_label'
            : 'version_label',
          {
            version_number: formatMessage('version', {
              major: version.node.version.major,
              minor: version.node.version.minor
            })
          }
        )}
      >
        <span>
          <span className={cx('lia-version-title')}>
            <span className={cx('lia-g-divider')}>
              {formatMessage(
                version?.node?.workflowAction === 'delete_draft'
                  ? formatMessage('draft_deleted', {
                      version_number: formatMessage('version', {
                        major: version.node.version.major,
                        minor: formatMessage('version_unknown')
                      })
                    })
                  : 'version',
                {
                  major: version.node.version.major,
                  minor: version.node.version.minor
                }
              )}
            </span>
            {isEqual(currentVersion, version.node.version) &&
              version?.node?.workflowAction !== 'delete_draft' && (
                <span className={cx('lia-g-divider lia-version-divider')}>
                  {formatMessage('current')}
                </span>
              )}
          </span>
          <small className={cx('lia-version-metadata')}>
            <UserLogin className={cx('lia-g-divider')} user={version.node.changedBy} />
            <span className={cx('lia-g-divider')}>
              {formatRelativeTime(version.node.changedOn)}
            </span>
          </small>
        </span>
        <span className={cx('lia-accordion-icon-wrap lia-g-icon-btn')}>
          <Icon
            icon={Icons.ChevronRightIcon}
            size={IconSize.PX_16}
            className={cx('lia-accordion-icon')}
          />
        </span>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={version.node.revision}>
        <span>{fetchVersionDetails(version.node.version, publishState)}</span>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default MessageVersionDrawer;
